"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var solid_1 = require("@heroicons/react/24/solid");
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var mainModules_1 = require("../../../../utils/mainModules");
var routes_1 = require("../../../../utils/routes");
var titles_1 = require("../../../../utils/titles");
var navigationConfig_1 = require("../../utils/navigationConfig");
var Breadcrumb = function () {
    var location = (0, react_router_dom_1.useLocation)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var path = location.pathname;
    var breadcrumbItems = (0, react_1.useMemo)(function () {
        var items = [{ title: 'Accueil', link: routes_1.ROUTES.HOME }];
        var findInSubModules = function (modulePath, subModules, currentPath) {
            var e_1, _a;
            try {
                for (var subModules_1 = __values(subModules), subModules_1_1 = subModules_1.next(); !subModules_1_1.done; subModules_1_1 = subModules_1.next()) {
                    var subModule = subModules_1_1.value;
                    if ('subModules' in subModule) {
                        var result = findInSubModules(modulePath, subModule.subModules, __spreadArray(__spreadArray([], __read(currentPath), false), [{ title: subModule.title, link: routes_1.ROUTES.HOME }], false));
                        if (result) {
                            return result;
                        }
                    }
                    else if ('route' in subModule && subModule.route && (0, react_router_dom_1.matchPath)(subModule.route, modulePath)) {
                        return __spreadArray(__spreadArray([], __read(currentPath), false), [{ title: subModule.title, link: subModule.route }], false);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (subModules_1_1 && !subModules_1_1.done && (_a = subModules_1.return)) _a.call(subModules_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return null;
        };
        var findInModules = function (modules, currentPath) {
            var e_2, _a;
            // remove everything after the first section /securing/verify-coproprietes => /securing
            var pathWithoutLastPart = path.split('/').slice(0, -1).join('/');
            try {
                for (var _b = __values(Object.entries(modules)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = __read(_c.value, 2), moduleValue = _d[1];
                    if (!moduleValue.subModules) {
                        continue;
                    }
                    var result = findInSubModules(pathWithoutLastPart, moduleValue.subModules, __spreadArray(__spreadArray([], __read(currentPath), false), [{ title: moduleValue.title, link: routes_1.ROUTES.HOME }], false));
                    if (result) {
                        return result;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
            return null;
        };
        var moduleResult = findInModules(mainModules_1.MODULES, []);
        if (moduleResult) {
            items.push.apply(items, __spreadArray([], __read(moduleResult), false));
        }
        else {
            var allNavigationItems = __spreadArray(__spreadArray([], __read(navigationConfig_1.navigationList), false), __read(navigationConfig_1.navigationListBO), false);
            var currentItem_1 = allNavigationItems.flatMap(function (section) { return section.items; }).find(function (item) { return (0, react_router_dom_1.matchPath)(item.href, path); });
            if (currentItem_1) {
                var currentSection = allNavigationItems.find(function (section) { return section.items.includes(currentItem_1); });
                if (currentSection && currentSection.module) {
                    var moduleInfo = mainModules_1.MODULES[currentSection.module];
                    if (moduleInfo) {
                        items.push({ title: moduleInfo.title, link: routes_1.ROUTES.HOME });
                    }
                }
                if (currentSection && currentSection.module !== currentItem_1.title) {
                    items.push({ title: currentItem_1.title, link: currentItem_1.href });
                }
            }
        }
        var pageTitle = titles_1.ROUTE_TITLES[path];
        if (pageTitle && pageTitle !== items[items.length - 1].title) {
            items.push({ title: pageTitle, link: path });
        }
        return items;
    }, [path]);
    var handleMainModuleClick = function (moduleKey) {
        // find module key for which title is moduleKey
        var moduleKeyKey = Object.keys(mainModules_1.MODULES).find(function (key) { return mainModules_1.MODULES[key].title === moduleKey; });
        navigate(routes_1.ROUTES.HOME, { state: { selectedMainModule: moduleKeyKey } });
    };
    if (breadcrumbItems.length <= 2) {
        return null;
    }
    return (react_1.default.createElement("nav", { className: "text-white text-sm hidden lg:block", "aria-label": "Breadcrumb" },
        react_1.default.createElement("ol", { className: "list-none p-0 inline-flex" }, breadcrumbItems.map(function (item, index) { return (react_1.default.createElement("li", { key: index, className: "flex items-center" }, index < breadcrumbItems.length - 1 ? (react_1.default.createElement(react_1.default.Fragment, null,
            Object.keys(mainModules_1.MODULES).some(function (key) { return mainModules_1.MODULES[key].title === item.title; }) ? (react_1.default.createElement("button", { onClick: function () { return handleMainModuleClick(item.title); }, className: "hover:underline" }, item.title)) : (react_1.default.createElement(react_router_dom_1.Link, { to: item.link, className: "hover:underline" }, item.title)),
            react_1.default.createElement(solid_1.ChevronRightIcon, { className: "w-4 h-4 mx-2" }))) : (react_1.default.createElement("span", { className: "text-gray-200", "aria-current": "page" }, item.title)))); }))));
};
exports.default = Breadcrumb;
