"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setVigilanceStats = exports.setSelectedModule = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    selectedModule: null,
    vigilanceStats: null,
};
var moduleSlice = (0, toolkit_1.createSlice)({
    name: 'module',
    initialState: initialState,
    reducers: {
        setSelectedModule: function (state, action) {
            state.selectedModule = action.payload;
        },
        setVigilanceStats: function (state, action) {
            state.vigilanceStats = action.payload;
        },
    },
});
exports.setSelectedModule = (_a = moduleSlice.actions, _a.setSelectedModule), exports.setVigilanceStats = _a.setVigilanceStats;
exports.default = moduleSlice.reducer;
