"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var outline_1 = require("@heroicons/react/24/outline");
var flowbite_react_1 = require("flowbite-react");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var formatters_1 = require("../../../../common-utils/formatters");
var JWTHeader_1 = require("../../../utils/JWTHeader");
var routes_1 = require("../../../utils/routes");
var Notification_1 = require("../../common/Notification");
function NewsletterPreview() {
    var _this = this;
    var id = (0, react_router_dom_1.useParams)().id;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var company = (0, react_redux_1.useSelector)(function (state) { return state.auth.company; });
    var jwt = (0, react_redux_1.useSelector)(function (state) { return state.auth.token; });
    var _a = __read((0, react_1.useState)(null), 2), newsletter = _a[0], setNewsletter = _a[1];
    var _b = __read((0, react_1.useState)(true), 2), isLoading = _b[0], setIsLoading = _b[1];
    var fetchData = function () { return __awaiter(_this, void 0, void 0, function () {
        var newsletterResponse, newsletterData, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    return [4 /*yield*/, fetch("/api/newsletters/available/".concat(id), (0, JWTHeader_1.JWTHeader)(jwt))];
                case 1:
                    newsletterResponse = _a.sent();
                    if (!newsletterResponse.ok) {
                        throw new Error('Failed to fetch newsletter');
                    }
                    return [4 /*yield*/, newsletterResponse.json()];
                case 2:
                    newsletterData = _a.sent();
                    setNewsletter(newsletterData);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error fetching data:', error_1);
                    (0, Notification_1.showToast)('error', 'Une erreur est survenue lors du chargement de la newsletter.');
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        if (!id || !jwt) {
            return;
        }
        fetchData();
    }, [id, jwt]);
    if (isLoading) {
        return (react_1.default.createElement("div", { className: "flex justify-center items-center min-h-80" },
            react_1.default.createElement(flowbite_react_1.Spinner, { size: "xl" })));
    }
    if (!newsletter) {
        return (react_1.default.createElement("div", { className: "flex justify-center items-center min-h-80" },
            react_1.default.createElement("p", { className: "text-lg text-gray-600" }, "Newsletter non trouv\u00E9e")));
    }
    var handleGoBack = function () {
        navigate(routes_1.ROUTES.COMMUNICATION_NEWSLETTERS);
    };
    var handleSendNewsletter = function () {
        (0, Notification_1.showToast)('info', 'Fonctionnalité à venir : en cours de développement');
    };
    return (react_1.default.createElement("div", null,
        react_1.default.createElement("div", { className: "bg-white p-4 rounded-lg shadow-md mb-8 overflow-x-scroll" },
            react_1.default.createElement("div", { className: "flex flex-col mb-8 gap-4" },
                react_1.default.createElement("div", { className: "flex justify-between items-center" },
                    react_1.default.createElement("button", { onClick: handleGoBack, className: "text-rgpd-blue flex items-center mr-4" },
                        react_1.default.createElement(outline_1.ArrowLeftIcon, { className: "h-6 w-6" })),
                    react_1.default.createElement("h1", { className: "text-2xl font-bold grow" }, "Pr\u00E9visualisation de la newsletter")),
                react_1.default.createElement("p", { className: "text-lg" },
                    "Vous consultez actuellement la pr\u00E9visualisation d'une newsletter. Cette vue vous permet de v\u00E9rifier le contenu et la mise en forme avant de proc\u00E9der \u00E0 un envoi.",
                    react_1.default.createElement("br", null),
                    react_1.default.createElement("br", null),
                    "Prenez le temps de relire attentivement le contenu pour vous assurer que les informations sont exactes et pertinentes pour vos propri\u00E9taires."),
                react_1.default.createElement("p", { className: "text-sm text-gray-500" },
                    "Date de mise \u00E0 disposition de la Newsletter par D\u00E9clarations Juridiques : ",
                    (0, formatters_1.formatDate)(newsletter.createdAt))),
            react_1.default.createElement("div", { className: "bg-gray-50 rounded-lg" },
                react_1.default.createElement("div", { className: "bg-white shadow-md p-6 mb-4 rounded-lg" },
                    react_1.default.createElement("div", { className: "flex justify-between gap-4" },
                        react_1.default.createElement("h1", { className: "text-2xl font-bold text-gray-800 mb-2" },
                            "Objet : ",
                            newsletter.subject),
                        react_1.default.createElement("button", { className: "text-rgpd-blue hover:text-rgpd-white hover:bg-rgpd-blue transition-colors flex items-center border border-rgpd-blue rounded-md px-2 py-1", onClick: handleSendNewsletter },
                            react_1.default.createElement("span", null, "Envoyer"),
                            react_1.default.createElement(outline_1.PaperAirplaneIcon, { className: "h-6 w-6 ml-2 inline" })))),
                react_1.default.createElement("div", { className: "bg-white shadow-md p-4 mb-6 text-center" },
                    react_1.default.createElement("p", { className: "text-gray-700" },
                        "Cette newsletter vous est offerte par votre syndic :",
                        react_1.default.createElement("span", { className: "font-semibold ml-1" }, (company === null || company === void 0 ? void 0 : company.name) || 'Votre syndic'))),
                react_1.default.createElement("div", { className: "max-w-4xl mx-auto px-4 pb-12" },
                    react_1.default.createElement("div", { className: "bg-white rounded-lg shadow-md p-8", dangerouslySetInnerHTML: { __html: newsletter.content } }))))));
}
exports.default = NewsletterPreview;
