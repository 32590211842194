"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var outline_1 = require("@heroicons/react/24/outline");
var Sentry = __importStar(require("@sentry/react"));
var react_1 = __importStar(require("react"));
function Page404() {
    (0, react_1.useEffect)(function () {
        // Create a custom error object to send to Sentry
        var error = new Error('404 - Page not found');
        // Add additional context to the error
        error.status = 404;
        error.message = 'Page not found';
        error.url = window.location.href;
        // Send the error to Sentry
        Sentry.captureException(error, {
            level: 'warning', // You can change this to the desired level
        });
    }, []);
    var goBack = function () {
        window.history.back();
    };
    return (react_1.default.createElement("div", { className: "flex items-center justify-center px-4 sm:px-6 lg:px-8" },
        react_1.default.createElement("div", { className: "max-w-2xl w-full space-y-8 bg-white p-12 rounded-xl shadow-2xl" },
            react_1.default.createElement("div", { className: "grid place-content-center" },
                react_1.default.createElement("img", { alt: "D\u00E9clarations Juridiques Logo", src: require('../../../img/logo.svg'), className: "w-96 mx-auto" })),
            react_1.default.createElement("div", { className: "text-center" },
                react_1.default.createElement("h2", { className: "mt-6 text-4xl font-extrabold text-gray-900" }, "404"),
                react_1.default.createElement("p", { className: "mt-2 text-lg text-gray-600" }, "Oups ! Page non trouv\u00E9e")),
            react_1.default.createElement("div", { className: "mt-10 space-y-8" },
                react_1.default.createElement("div", { className: "rounded-md shadow-sm -space-y-px" },
                    react_1.default.createElement("div", { className: "px-6 py-6 bg-gray-50 sm:p-8" },
                        react_1.default.createElement("h3", { className: "text-xl leading-6 font-medium text-gray-900" }, "Nous n'avons pas trouv\u00E9 la page que vous recherchez"),
                        react_1.default.createElement("div", { className: "mt-4 max-w-xl text-base text-gray-500" },
                            react_1.default.createElement("p", null, "La page demand\u00E9e n'a pas pu \u00EAtre trouv\u00E9e. Elle a peut-\u00EAtre \u00E9t\u00E9 supprim\u00E9e, renomm\u00E9e ou n'existe pas.")))),
                react_1.default.createElement("div", { className: "flex items-center justify-between" },
                    react_1.default.createElement("button", { onClick: goBack, className: "group relative w-full flex justify-center py-3 px-6 border border-transparent text-lg font-medium rounded-md text-rgpd-white bg-rgpd-blue hover:bg-rgpd-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" },
                        react_1.default.createElement("span", { className: "absolute left-0 inset-y-0 flex items-center pl-3" },
                            react_1.default.createElement(outline_1.ArrowLeftIcon, { className: "h-6 w-6 text-rgpd-white", "aria-hidden": "true" })),
                        "Retour"))),
            react_1.default.createElement("div", { className: "mt-10 text-center" },
                react_1.default.createElement("a", { href: "/", className: "text-lg font-medium text-rgpd-blue hover:text-rgpd-blue-dark" }, "Retour \u00E0 l'accueil")))));
}
exports.default = Page404;
