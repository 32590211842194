"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiFileMap = void 0;
exports.apiFileMap = [
    { apiKey: 'details_identification_numimmatriculationcopropriete', fileKey: 'numero_dimmatriculation', label: 'Numéro d\'immatriculation' },
    { apiKey: 'details_identification_nomcopropriete', fileKey: 'nom_dusage_de_la_copropriete', label: 'Nom de la copropriété' },
    { apiKey: 'siret', fileKey: 'siret_representant_legal_si_existe', label: 'SIRET du syndic' },
    { apiKey: 'details_inforattachement_datedebutmandat', fileKey: null, label: 'Date de début du mandat' },
    { apiKey: 'details_inforattachement_datefinmandat', fileKey: 'date_de_fin_du_dernier_mandat', label: 'Date de fin du mandat' },
    { apiKey: 'details_identification_adresseprincipale_voie', fileKey: 'numero_et_voie_adresse_de_reference', label: 'Numéro et voie' },
    { apiKey: 'details_identification_adresseprincipale_codepostal', fileKey: 'code_postal_adresse_de_reference', label: 'Code postal' },
    { apiKey: 'details_identification_adresseprincipale_commune', fileKey: 'commune_adresse_de_reference', label: 'Commune' },
    { apiKey: 'details_identification_nbasl', fileKey: 'nombre_dasl_auxquelles_est_rattache_le_syndicat_de_coproprietai', label: 'Nombre d\'ASL rattachées' },
    { apiKey: 'details_identification_nbaful', fileKey: 'nombre_daful_auxquelles_est_rattache_le_syndicat_de_coproprieta', label: 'Nombre d\'AFUL rattachées' },
    { apiKey: 'details_identification_nbunionssyndicats', fileKey: 'nombre_dunions_de_syndicats_auxquelles_est_rattache_le_syndicat', label: 'Nombre d\'unions de syndicats rattachées' },
    { apiKey: 'details_identification_nblots', fileKey: 'nombre_total_de_lots', label: 'Nombre total de lots' },
    { apiKey: 'details_identification_nblotshabburcom', fileKey: 'nombre_total_de_lots_a_usage_dhabitation_de_bureaux_ou_de_comme', label: 'Nombre de lots principaux (habitation, bureau, commerce)' },
    { apiKey: 'details_identification_nblotshab', fileKey: 'nombre_de_lots_a_usage_dhabitation', label: 'Nombre de lots à usage d\'habitation' },
    { apiKey: 'details_identification_nblotsstationnement', fileKey: 'nombre_de_lots_de_stationnement', label: 'Nombre de lots de stationnement' },
    { apiKey: 'details_donneestechniques_typechauffage', fileKey: 'type_de_chauffage', label: 'Type de chauffage' },
    { apiKey: 'details_donneestechniques_energiechauffagecollectifnonurbain', fileKey: 'energie_utilisee', label: 'Énergie utilisée pour le chauffage' },
    { apiKey: 'details_donneestechniques_nbascenseurs', fileKey: 'nombre_dascenseurs', label: 'Nombre d\'ascenseurs' },
    { apiKey: 'details_donneestechniques_nbbatimentstotal', fileKey: 'nombre_de_batiments', label: 'Nombre de bâtiments' },
    { apiKey: 'details_donneesfinancieres_dateag', fileKey: 'date_de_la_derniere_assemblee_generale', label: 'Date de la dernière assemblée générale' },
    { apiKey: 'details_donneesfinancieres_datecloturecomptes', fileKey: 'date_de_fin_de_lexercice_comptable', label: 'Date de clôture des comptes' },
    { apiKey: 'details_donneesfinancieres_datedebutexercice', fileKey: 'date_de_debut_de_lexercice_comptable', label: 'Date de début d\'exercice' },
    { apiKey: 'datedernieremodif', fileKey: 'date_de_la_derniere_maj', label: 'Date de dernière mise à jour' },
];
