"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CapturamaSsoService = exports.CapturamaSsoModule = void 0;
var Notification_1 = require("../../components/common/Notification");
var api_1 = require("../../utils/api");
var JWTHeader_1 = require("../../utils/JWTHeader");
var CapturamaSsoModule;
(function (CapturamaSsoModule) {
    CapturamaSsoModule["REAL_ESTATE_SOLD"] = "real-estate-sold";
    CapturamaSsoModule["CADASTRE"] = "cadastre";
})(CapturamaSsoModule || (exports.CapturamaSsoModule = CapturamaSsoModule = {}));
var CapturamaSsoService = /** @class */ (function () {
    function CapturamaSsoService() {
    }
    CapturamaSsoService.getModuleUri = function (companyId, module, search) {
        return (0, api_1.getRedirectToModule)(module, companyId, search);
    };
    CapturamaSsoService.getModulePreAuthUri = function (companyId, module) {
        switch (module) {
            case CapturamaSsoModule.REAL_ESTATE_SOLD:
                return (0, api_1.getRealEstateSoldModule)(companyId);
            case CapturamaSsoModule.CADASTRE:
                return (0, api_1.getCadastreModule)(companyId);
        }
    };
    CapturamaSsoService.redirectToCapturamaSsoModule = function (companyId, jwt, module, search) {
        return __awaiter(this, void 0, void 0, function () {
            var moduleUri, response, responseJson, urlWithToken, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        // do preauth here before redirecting to the module
                        return [4 /*yield*/, this.preAuthToCapturamaSsoModule(companyId, jwt, module)];
                    case 1:
                        // do preauth here before redirecting to the module
                        _a.sent();
                        moduleUri = this.getModuleUri(companyId, module, search);
                        return [4 /*yield*/, fetch(moduleUri, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: (0, JWTHeader_1.JWTAuthorization)(jwt),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        if (!response.ok) {
                            throw new Error("Error fetching Capturama ".concat(module, " module"));
                        }
                        return [4 /*yield*/, response.json()];
                    case 3:
                        responseJson = _a.sent();
                        urlWithToken = new URL(responseJson.redirect);
                        if (search) {
                            urlWithToken.searchParams.append('search', search);
                        }
                        window.open(urlWithToken.toString(), '_blank');
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        console.error("Error fetching Capturama ".concat(module, " module:"), error_1);
                        (0, Notification_1.showToast)('error', 'Une erreur est survenue lors de la redirection.');
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CapturamaSsoService.preAuthToCapturamaSsoModule = function (companyId, jwt, module) {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, fetch(this.getModulePreAuthUri(companyId, module), {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: (0, JWTHeader_1.JWTAuthorization)(jwt),
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        if (!response.ok) {
                            throw new Error("Error pre auth for Capturama ".concat(module, " module"));
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.error("Error fetching Capturama pre auth ".concat(module, " module:"), error_2);
                        (0, Notification_1.showToast)('error', 'Une erreur est survenue lors de la redirection.');
                        throw new Error(error_2);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return CapturamaSsoService;
}());
exports.CapturamaSsoService = CapturamaSsoService;
