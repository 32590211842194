"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.navigationListBO = exports.navigationList = exports.nonMenuIncludedNavigationAdmin = exports.nonMenuIncludedNavigation = exports.dashboardRoutes = void 0;
var outline_1 = require("@heroicons/react/24/outline");
var mainModules_1 = require("../../../utils/mainModules");
var routes_1 = require("../../../utils/routes");
exports.dashboardRoutes = (_a = {},
    _a[mainModules_1.MODULE_SAFETY] = routes_1.ROUTES.SECURING_DASHBOARD,
    _a[mainModules_1.MODULE_ANALYZING] = routes_1.ROUTES.ANALYZING_DASHBOARD,
    _a[mainModules_1.MODULE_INCOMING_TRANSACTION] = routes_1.ROUTES.PROSPECTING_INCOMING_TRANSACTION,
    _a[mainModules_1.MODULE_INCOMING_RENT] = routes_1.ROUTES.PROSPECTING_INCOMING_RENT,
    _a[mainModules_1.MODULE_DIRECT_ALL_NEW_DPE] = routes_1.ROUTES.PROSPECTING_DIRECT_ALL_NEW_DPE,
    _a[mainModules_1.MODULE_DIRECT_TRANSACTION] = routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION,
    _a[mainModules_1.MODULE_DIRECT_RENT] = routes_1.ROUTES.PROSPECTING_DIRECT_RENT,
    _a[mainModules_1.MODULE_MONITORING] = routes_1.ROUTES.MONITORING_DASHBOARD,
    _a[mainModules_1.MODULE_COMMUNICATION] = routes_1.ROUTES.COMMUNICATION_DASHBOARD,
    _a);
exports.nonMenuIncludedNavigation = [
    routes_1.ROUTES.SETUP_DIFF_IMPORTED_RNIC_FILE_ID,
    routes_1.ROUTES.PROSPECTING_DIRECT_RENT_SCIS_SIREN,
    routes_1.ROUTES.QUICK_ACCESS_COPRO_MANAGING_ID,
    routes_1.ROUTES.COMMUNICATION_NEWSLETTER_PREVIEW,
];
exports.nonMenuIncludedNavigationAdmin = [
    routes_1.ROUTES.ADMIN_COMPANY_DETAILS,
    routes_1.ROUTES.ADMIN_BLOG_POST_EDIT,
    routes_1.ROUTES.ADMIN_BLOG_POST_NEW,
    routes_1.ROUTES.ADMIN_NEWSLETTER_NEW,
    routes_1.ROUTES.ADMIN_NEWSLETTER_EDIT,
];
exports.navigationList = [
    {
        module: mainModules_1.QUICK_ACCESS,
        isToggleable: false,
        items: [
            { title: 'Tableau de bord', href: '', icon: outline_1.HomeIcon, current: false, freemium: true },
            { title: 'Mes copropriétés', href: routes_1.ROUTES.QUICK_ACCESS_COPRO_MANAGING, icon: outline_1.BuildingOffice2Icon, current: false, freemium: true },
            { title: 'Vigilance copropriétés', href: routes_1.ROUTES.QUICK_ACCESS_COPRO_VIGILANCE, icon: outline_1.BuildingOffice2Icon, current: false, freemium: true },
            { title: 'Répartition des gestionnaires', href: routes_1.ROUTES.QUICK_ACCESS_GESTIONNAIRES_COPROS, icon: outline_1.GlobeEuropeAfricaIcon, current: false, freemium: true },
            { title: 'Assemblée générale', href: routes_1.ROUTES.QUICK_ACCESS_GENERAL_ASSEMBLY, icon: outline_1.CalendarDaysIcon, current: false, freemium: true },
        ],
    },
    {
        module: mainModules_1.MODULE_SAFETY,
        isToggleable: true,
        items: [
            { title: 'Validation des copropriétés', href: routes_1.ROUTES.SETUP_VERIFY_COPROPRIETES, icon: outline_1.ShieldExclamationIcon, current: false, freemium: true },
            { title: 'Vérification des adresses', href: routes_1.ROUTES.SETUP_ADD_ADDRESSES, icon: outline_1.MapPinIcon, current: false, freemium: true },
            { title: 'Ajout des copropriétés', href: routes_1.ROUTES.SETUP_ADD_COPROPRIETES, icon: outline_1.CheckCircleIcon, current: false, freemium: true },
            { title: 'Surface des copropriétés', href: routes_1.ROUTES.SETUP_COPRO_AREA, icon: outline_1.ArrowsPointingOutIcon, current: false, freemium: true },
            { title: 'Attribution des collaborateurs', href: routes_1.ROUTES.SETUP_ADD_COLLABORATORS, icon: outline_1.UsersIcon, current: false, freemium: false },
            { title: 'Configuration des alertes', href: routes_1.ROUTES.SETUP_CONFIGURE_NOTIFICATIONS, icon: outline_1.PencilSquareIcon, current: false, freemium: false },
            { title: 'Anomalies détectées suite import', href: routes_1.ROUTES.SETUP_DIFF_IMPORTED_RNIC, icon: outline_1.ExclamationTriangleIcon, current: false, freemium: true },
        ],
    },
    {
        module: mainModules_1.MODULE_ANALYZING,
        isToggleable: true,
        items: [
            { title: 'Vision du marché', href: routes_1.ROUTES.ANALYZING_MARKET_VISION, current: false, freemium: true },
            { title: 'Vision des DPEs', href: routes_1.ROUTES.ANALYZING_DPE_VISION, current: false, freemium: true },
        ],
    },
    {
        module: mainModules_1.MODULE_MONITORING,
        isToggleable: true,
        items: [
            { title: 'Sociétés / Commerces / Pieds d\'immeubles', href: routes_1.ROUTES.MONITORING_BAUX_COMMERCIAUX, current: false, freemium: false },
            { title: 'Baux à échéance', href: routes_1.ROUTES.MONITORING_BAUX_A_ECHEANCE, current: false, freemium: false },
            { title: 'Commerces et sociétés (surveillance)', href: routes_1.ROUTES.MONITORING_ENTREPRISES_EN_DEFAILLANCE, current: false, freemium: false },
            { title: 'Surveillance gestion locative', href: routes_1.ROUTES.MONITORING_ENTREPRISES_SUIVIES, current: false, freemium: false },
        ],
    },
    {
        module: mainModules_1.MODULE_COMMUNICATION,
        isToggleable: true,
        items: [
            { title: 'Newsletters', href: routes_1.ROUTES.COMMUNICATION_NEWSLETTERS, current: false, freemium: true },
            { title: 'Page à inclure', href: routes_1.ROUTES.COMMUNICATION_PAGE_SITE, current: false, freemium: true },
            { title: 'Script d\'appel', href: routes_1.ROUTES.COMMUNICATION_SCRIPT_APPEL, current: false, freemium: true },
        ],
    },
    {
        module: mainModules_1.MODULE_INCOMING_TRANSACTION,
        isToggleable: true,
        items: [
            { title: 'Modèles de mails / courriers', href: routes_1.ROUTES.PROSPECTING_INCOMING_TRANSACTION_MAIL_MODELS, current: false, freemium: false },
            { title: 'Fiche de copropriété / Avis de valeur', href: routes_1.ROUTES.PROSPECTING_INCOMING_TRANSACTION_GENERATE_COPRO_REPORT, current: false, freemium: false },
        ],
    },
    {
        module: mainModules_1.MODULE_INCOMING_RENT,
        isToggleable: true,
        items: [
            { title: 'Modèles de mails / courriers', href: routes_1.ROUTES.PROSPECTING_INCOMING_RENT_MAIL_MODELS, current: false, freemium: false },
            { title: 'Fiche de copropriété', href: routes_1.ROUTES.PROSPECTING_INCOMING_RENT_GENERATE_COPRO_REPORT, current: false, freemium: false },
        ],
    },
    {
        module: mainModules_1.MODULE_DIRECT_ALL_NEW_DPE,
        isToggleable: true,
        items: [
            { title: 'Nouvelles opportunités (DPE)', href: routes_1.ROUTES.PROSPECTING_DIRECT_ALL_NEW_DPE_NEW_OPPORTUNITIES, current: false, freemium: false },
            { title: 'Propriétaires identifiés', href: routes_1.ROUTES.PROSPECTING_DIRECT_ALL_NEW_DPE_IDENTIFIED_OWNER, current: false, freemium: false },
            { title: 'DPE en cours de traitement', href: routes_1.ROUTES.PROSPECTING_DIRECT_ALL_NEW_DPE_COMMUNICATE, current: false, freemium: false },
            { title: 'Suivi de la performance', href: routes_1.ROUTES.PROSPECTING_DIRECT_ALL_NEW_DPE_TEAM_PERFORMANCE, current: false, freemium: false },
        ],
    },
    {
        module: mainModules_1.MODULE_DIRECT_TRANSACTION,
        isToggleable: true,
        items: [
            { title: 'Mook de formation', href: routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION_MOOK_FORMATION, current: false, freemium: false },
            { title: 'Prompt téléphonique', href: routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION_PHONE_PROMPT, current: false, freemium: false },
            { title: 'Modèles de mails / courriers', href: routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION_MAIL_MODELS, current: false, freemium: false },
            { title: 'Fiche de copropriété / Avis de valeur', href: routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION_GENERATE_COPRO_REPORT, current: false, freemium: false },
            { title: 'Fiche des DPEs', href: routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION_GENERATE_DPE_REPORT, current: false, freemium: false },
            { title: 'Fiche des ventes', href: routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION_GENERATE_SALES_REPORT, current: false, freemium: false },
        ],
    },
    {
        module: mainModules_1.MODULE_DIRECT_RENT,
        isToggleable: true,
        items: [
            { title: 'Mook de formation', href: routes_1.ROUTES.PROSPECTING_DIRECT_RENT_MOOK_FORMATION, current: false, freemium: false },
            { title: 'Prompt téléphonique', href: routes_1.ROUTES.PROSPECTING_DIRECT_RENT_PHONE_PROMPT, current: false, freemium: false },
            { title: 'Modèles de mails / courriers', href: routes_1.ROUTES.PROSPECTING_DIRECT_RENT_MAIL_MODELS, current: false, freemium: false },
            { title: 'Fiche des entreprises / des SCIs  ', href: routes_1.ROUTES.PROSPECTING_DIRECT_RENT_SCIS, current: false, freemium: false },
        ],
    },
];
exports.navigationListBO = [
    {
        module: '',
        items: [
            { title: 'Liste des entreprises', href: routes_1.ROUTES.ADMIN_COMPANIES_LIST, icon: outline_1.BuildingOffice2Icon, current: false, freemium: true },
            { title: 'Entreprises à valider', href: routes_1.ROUTES.ADMIN_COMPANY_VERIFICATION, icon: outline_1.BuildingStorefrontIcon, current: false, freemium: true },
            { title: 'Validation des copropriétés', href: routes_1.ROUTES.ADMIN_COPRO_VALIDATION, icon: outline_1.CheckCircleIcon, current: false, freemium: true },
            { title: 'Import des ASL/AFUL/Copro', href: routes_1.ROUTES.ADMIN_IMPORT, icon: outline_1.ArrowDownTrayIcon, current: false, freemium: true },
            { title: 'Import d\'un fichier différentiel', href: routes_1.ROUTES.ADMIN_DIFF, icon: outline_1.ArrowDownOnSquareStackIcon, current: false, freemium: true },
            { title: 'Import des SIRET suivis', href: routes_1.ROUTES.ADMIN_SIRET_TRACKED_IMPORT, icon: outline_1.BriefcaseIcon, current: false, freemium: true },
            {
                title: 'Articles de blog',
                href: routes_1.ROUTES.ADMIN_BLOG_POSTS,
                icon: outline_1.DocumentTextIcon,
                current: false,
                freemium: true,
            },
            {
                title: 'Newsletters',
                href: routes_1.ROUTES.ADMIN_NEWSLETTER,
                icon: outline_1.DocumentTextIcon,
                current: false,
                freemium: true,
            },
        ],
    },
];
