"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = NewsletterSidebar;
var react_1 = require("@headlessui/react");
var outline_1 = require("@heroicons/react/24/outline");
var flowbite_react_1 = require("flowbite-react");
var react_2 = __importStar(require("react"));
var react_beautiful_dnd_1 = require("react-beautiful-dnd");
var react_redux_1 = require("react-redux");
var formatters_1 = require("../../../../../../common-utils/formatters");
var Newsletter_1 = require("../../../../../@types/Newsletter");
var JWTHeader_1 = require("../../../../../utils/JWTHeader");
var routes_1 = require("../../../../../utils/routes");
var Notification_1 = require("../../../../common/Notification");
function NewsletterSidebar(_a) {
    var _this = this;
    var newsletter = _a.newsletter, onUpdate = _a.onUpdate, onSubjectChange = _a.onSubjectChange, isSaving = _a.isSaving, lastSaved = _a.lastSaved, onDelete = _a.onDelete, showTemplateSelector = _a.showTemplateSelector, isNewNewsletter = _a.isNewNewsletter, onBlogPostCountChange = _a.onBlogPostCountChange, blogPostCount = _a.blogPostCount, onBlogPostsChange = _a.onBlogPostsChange, blogPostSelectionMode = _a.blogPostSelectionMode, onBlogPostSelectionModeChange = _a.onBlogPostSelectionModeChange, selectedBlogPosts = _a.selectedBlogPosts, onSendTest = _a.onSendTest, onNavigate = _a.onNavigate, onAIContentGenerated = _a.onAIContentGenerated, hasTemplate = _a.hasTemplate;
    var _b = __read((0, react_2.useState)(''), 2), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = __read((0, react_2.useState)([]), 2), searchResults = _c[0], setSearchResults = _c[1];
    var _d = __read((0, react_2.useState)(false), 2), isSearching = _d[0], setIsSearching = _d[1];
    var _e = __read((0, react_2.useState)(''), 2), testEmail = _e[0], setTestEmail = _e[1];
    var _f = __read((0, react_2.useState)(false), 2), isSendingTest = _f[0], setIsSendingTest = _f[1];
    var _g = __read((0, react_2.useState)(false), 2), showSaveConfirmation = _g[0], setShowSaveConfirmation = _g[1];
    var _h = __read((0, react_2.useState)(''), 2), aiPrompt = _h[0], setAiPrompt = _h[1];
    var _j = __read((0, react_2.useState)(false), 2), isGenerating = _j[0], setIsGenerating = _j[1];
    var jwt = (0, react_redux_1.useSelector)(function (state) { return state.auth.token; });
    var handleSave = function () {
        if (isNewNewsletter) {
            setShowSaveConfirmation(true);
        }
        else {
            onUpdate({
                subject: newsletter.subject,
                content: newsletter.content,
                status: newsletter.status,
            });
        }
    };
    var handleStatusChange = function (status) {
        onUpdate(__assign(__assign({}, newsletter), { status: status }));
    };
    var searchBlogPosts = function (term) { return __awaiter(_this, void 0, void 0, function () {
        var response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!term) {
                        setSearchResults([]);
                        return [2 /*return*/];
                    }
                    setIsSearching(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    return [4 /*yield*/, fetch("/api/public/blog-posts/search?q=".concat(encodeURIComponent(term)))];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Erreur lors de la recherche des articles');
                    }
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _a.sent();
                    setSearchResults(data);
                    return [3 /*break*/, 6];
                case 4:
                    error_1 = _a.sent();
                    console.error('Erreur lors de la recherche des articles:', error_1);
                    return [3 /*break*/, 6];
                case 5:
                    setIsSearching(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handlePostSelection = function (post) {
        var updatedPosts = selectedBlogPosts.some(function (p) { return p.id === post.id; })
            ? selectedBlogPosts.filter(function (p) { return p.id !== post.id; })
            : __spreadArray(__spreadArray([], __read(selectedBlogPosts), false), [post], false);
        onBlogPostsChange(updatedPosts);
    };
    var handleDragEnd = function (result) {
        if (!result.destination) {
            return;
        }
        var items = __spreadArray([], __read(selectedBlogPosts), false);
        var _a = __read(items.splice(result.source.index, 1), 1), reorderedItem = _a[0];
        items.splice(result.destination.index, 0, reorderedItem);
        onBlogPostsChange(items);
    };
    var renderSelectedPosts = function () {
        if (selectedBlogPosts.length === 0) {
            return null;
        }
        return (react_2.default.createElement("div", { className: "mt-3" },
            react_2.default.createElement("h4", { className: "text-sm font-medium mb-2" }, "Articles s\u00E9lectionn\u00E9s:"),
            react_2.default.createElement(react_beautiful_dnd_1.DragDropContext, { onDragEnd: handleDragEnd },
                react_2.default.createElement(react_beautiful_dnd_1.Droppable, { droppableId: "selected-posts" }, function (dropProvided) { return (react_2.default.createElement("div", __assign({}, dropProvided.droppableProps, { ref: dropProvided.innerRef, className: "space-y-2" }),
                    selectedBlogPosts.map(function (post, index) { return (react_2.default.createElement(react_beautiful_dnd_1.Draggable, { key: post.id.toString(), draggableId: post.id.toString(), index: index }, function (dragProvided, snapshot) { return (react_2.default.createElement("div", __assign({ ref: dragProvided.innerRef }, dragProvided.draggableProps, { className: "flex items-center justify-between p-2 rounded border ".concat(snapshot.isDragging ? 'bg-blue-50 shadow-lg' : 'bg-white') }),
                        react_2.default.createElement("div", { className: "flex items-center flex-1 min-w-0" },
                            react_2.default.createElement("div", __assign({}, dragProvided.dragHandleProps, { className: "mr-2 cursor-grab text-gray-400 hover:text-gray-600" }), "\u22EE\u22EE"),
                            react_2.default.createElement("span", { className: "truncate text-sm" }, post.title)),
                        react_2.default.createElement("button", { onClick: function () { return handlePostSelection(post); }, className: "ml-2 text-red-600 hover:text-red-800" }, "\u00D7"))); })); }),
                    dropProvided.placeholder)); }))));
    };
    var handleSendTest = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!testEmail) {
                        (0, Notification_1.showToast)('error', 'Veuillez entrer une adresse email.');
                        return [2 /*return*/];
                    }
                    setIsSendingTest(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    return [4 /*yield*/, onSendTest(testEmail)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    setIsSendingTest(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleAIGeneration = function () { return __awaiter(_this, void 0, void 0, function () {
        var response, data, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!aiPrompt) {
                        (0, Notification_1.showToast)('error', 'Veuillez entrer un prompt pour la génération.');
                        return [2 /*return*/];
                    }
                    setIsGenerating(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    return [4 /*yield*/, fetch('/api/newsletter/generate-content', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: (0, JWTHeader_1.JWTAuthorization)(jwt),
                            },
                            body: JSON.stringify({
                                prompt: aiPrompt,
                                blogPosts: selectedBlogPosts,
                            }),
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Erreur lors de la génération du contenu');
                    }
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _a.sent();
                    onAIContentGenerated(data);
                    (0, Notification_1.showToast)('success', 'Contenu généré avec succès.');
                    return [3 /*break*/, 6];
                case 4:
                    error_2 = _a.sent();
                    console.error('Erreur:', error_2);
                    (0, Notification_1.showToast)('error', 'Erreur lors de la génération du contenu.');
                    return [3 /*break*/, 6];
                case 5:
                    setIsGenerating(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (react_2.default.createElement("div", { className: "w-full lg:w-80 bg-white lg:h-full rounded-lg p-2 overflow-y-auto" },
        react_2.default.createElement("div", { className: "space-y-6 bg-rgpd-white p-4" },
            isNewNewsletter && (react_2.default.createElement("div", { className: "mb-6" },
                react_2.default.createElement("button", { onClick: showTemplateSelector, className: "w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors flex items-center justify-center" },
                    react_2.default.createElement(outline_1.DocumentPlusIcon, { className: "w-5 h-5 mr-2" }),
                    "S\u00E9lectionner un template"))),
            react_2.default.createElement("div", null,
                react_2.default.createElement("h2", { className: "text-lg font-semibold mb-2" }, "Param\u00E8tres de la newsletter"),
                react_2.default.createElement("div", { className: "flex items-center text-sm text-gray-500" }, isSaving ? (react_2.default.createElement("div", { className: "flex items-center" },
                    react_2.default.createElement(flowbite_react_1.Spinner, { size: "sm", className: "mr-2" }),
                    "Sauvegarde en cours...")) : lastSaved && (react_2.default.createElement(react_2.default.Fragment, null,
                    "Derni\u00E8re sauvegarde : ",
                    (0, formatters_1.formatDateWithTime)(lastSaved.toISOString()))))),
            react_2.default.createElement("button", { onClick: handleSave, disabled: isSaving, className: "w-full px-2 py-2 text-white bg-rgpd-green rounded-md hover:bg-rgpd-green-dark transition-colors flex items-center justify-center ".concat(isSaving ? 'opacity-50 cursor-not-allowed' : '') }, isSaving ? (react_2.default.createElement(react_2.default.Fragment, null,
                react_2.default.createElement(flowbite_react_1.Spinner, { size: "sm", className: "mr-2" }),
                "Sauvegarde...")) : ('Sauvegarder')),
            react_2.default.createElement("div", { className: "space-y-2" },
                react_2.default.createElement("label", { className: "text-sm font-medium" }, "Objet"),
                react_2.default.createElement("input", { type: "text", value: newsletter.subject, onChange: function (e) { return onSubjectChange(e.target.value); }, className: "w-full p-2 border rounded-md", disabled: isSaving, required: true })),
            react_2.default.createElement("div", { className: "space-y-2" },
                react_2.default.createElement("label", { className: "text-sm font-medium" }, "Statut"),
                react_2.default.createElement("select", { value: newsletter.status, onChange: function (e) { return handleStatusChange(e.target.value); }, className: "w-full p-2 border rounded-md", disabled: isSaving },
                    react_2.default.createElement("option", { value: Newsletter_1.NewsletterStatusEnum.DRAFT }, "Brouillon"),
                    react_2.default.createElement("option", { value: Newsletter_1.NewsletterStatusEnum.PUBLIC }, "Public"))),
            isNewNewsletter && (react_2.default.createElement("div", { className: "mt-4" },
                react_2.default.createElement("h3", { className: "font-semibold mb-2" }, "Articles de blog"),
                react_2.default.createElement("div", { className: "flex gap-2 mb-3" },
                    react_2.default.createElement("button", { className: "text-sm px-2 py-0.5 rounded ".concat(blogPostSelectionMode === 'latest'
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-200 text-gray-700'), onClick: function () { return onBlogPostSelectionModeChange('latest'); } }, "Derniers articles"),
                    react_2.default.createElement("button", { className: "text-sm px-2 py-0.5 rounded ".concat(blogPostSelectionMode === 'manual'
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-200 text-gray-700'), onClick: function () { return onBlogPostSelectionModeChange('manual'); } }, "S\u00E9lection d'articles")),
                blogPostSelectionMode === 'latest' ? (react_2.default.createElement("div", { className: "mb-3" },
                    react_2.default.createElement("label", { className: "block text-sm text-gray-600 mb-1" }, "Nombre d'articles"),
                    react_2.default.createElement("input", { type: "number", min: "1", max: "10", value: blogPostCount, onChange: function (e) { return onBlogPostCountChange(parseInt(e.target.value, 10)); }, className: "w-full px-3 py-2 border rounded" }))) : (react_2.default.createElement("div", null,
                    react_2.default.createElement("div", { className: "relative mb-3" },
                        react_2.default.createElement("input", { type: "text", placeholder: "Rechercher des articles...", value: searchTerm, onChange: function (e) {
                                setSearchTerm(e.target.value);
                                searchBlogPosts(e.target.value);
                            }, className: "w-full px-3 py-2 border rounded pl-9" }),
                        react_2.default.createElement(outline_1.MagnifyingGlassCircleIcon, { className: "w-5 h-5 absolute left-2 top-2.5 text-gray-400" })),
                    isSearching && react_2.default.createElement("div", { className: "text-sm text-gray-500" }, "Recherche en cours..."),
                    searchResults.length > 0 && (react_2.default.createElement("div", { className: "max-h-60 overflow-y-auto border rounded" }, searchResults.map(function (post) { return (react_2.default.createElement("div", { key: post.id, className: "p-2 cursor-pointer hover:bg-gray-50 ".concat(selectedBlogPosts.some(function (p) { return p.id === post.id; })
                            ? 'bg-blue-50'
                            : ''), onClick: function () { return handlePostSelection(post); } },
                        react_2.default.createElement("div", { className: "text-sm font-medium" }, post.title),
                        react_2.default.createElement("div", { className: "text-xs text-gray-500" }, new Date(post.createdAt).toLocaleDateString()))); }))),
                    renderSelectedPosts())))),
            newsletter.id ? null : (react_2.default.createElement(flowbite_react_1.Tooltip, { content: react_2.default.createElement(react_2.default.Fragment, null,
                    "La g\u00E9n\u00E9ration IA n'est disponible",
                    react_2.default.createElement("br", null),
                    "qu'apr\u00E8s avoir s\u00E9lectionn\u00E9 un template"), placement: "auto", className: !hasTemplate ? 'block' : 'hidden', theme: {
                    target: 'w-full',
                    content: 'w-full',
                } },
                react_2.default.createElement("div", { className: "space-y-2 mb-4 w-full ".concat(!hasTemplate ? 'opacity-50 cursor-not-allowed' : '') },
                    react_2.default.createElement("h3", { className: "font-semibold mb-2" }, "G\u00E9n\u00E9ration IA"),
                    react_2.default.createElement("div", { className: "space-y-2" },
                        react_2.default.createElement("textarea", { value: aiPrompt, onChange: function (e) { return setAiPrompt(e.target.value); }, placeholder: "D\u00E9crivez le contenu que vous souhaitez g\u00E9n\u00E9rer...", className: "w-full p-2 border rounded-md h-24", disabled: isGenerating || !hasTemplate }),
                        react_2.default.createElement("button", { onClick: handleAIGeneration, disabled: isGenerating || !aiPrompt || !hasTemplate, className: "w-full px-4 py-2 text-white bg-purple-600 rounded-md hover:bg-purple-700 flex items-center justify-center ".concat((isGenerating || !hasTemplate) ? 'opacity-50 cursor-not-allowed' : '') }, isGenerating ? (react_2.default.createElement(react_2.default.Fragment, null,
                            react_2.default.createElement(flowbite_react_1.Spinner, { size: "sm", className: "mr-2" }),
                            "G\u00E9n\u00E9ration en cours...")) : (react_2.default.createElement(react_2.default.Fragment, null,
                            react_2.default.createElement(outline_1.SparklesIcon, { className: "w-5 h-5 mr-2" }),
                            "G\u00E9n\u00E9rer le contenu"))))))),
            react_2.default.createElement(flowbite_react_1.Tooltip, { content: react_2.default.createElement(react_2.default.Fragment, null,
                    "Les emails de test ne sont disponibles",
                    react_2.default.createElement("br", null),
                    "qu'apr\u00E8s la premi\u00E8re sauvegarde de la newsletter"), placement: "auto", className: !newsletter.id ? 'block' : 'hidden', theme: {
                    target: 'w-full',
                    content: 'w-full',
                } },
                react_2.default.createElement("div", { className: "mb-4 w-full ".concat(!newsletter.id ? 'opacity-50 cursor-not-allowed' : '') },
                    react_2.default.createElement("label", { className: "block text-gray-700 mb-2 font-semibold" }, "Envoyer un email de test"),
                    react_2.default.createElement("div", { className: "flex gap-2" },
                        react_2.default.createElement("input", { type: "email", value: testEmail, onChange: function (e) { return setTestEmail(e.target.value); }, placeholder: "Entrer une adresse email", className: "flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm", disabled: !newsletter.id }),
                        react_2.default.createElement("button", { onClick: handleSendTest, disabled: isSendingTest || !testEmail || !newsletter.id, className: "inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50" }, isSendingTest ? react_2.default.createElement(flowbite_react_1.Spinner, { size: "sm", className: "mr-2" })
                            : react_2.default.createElement(outline_1.PaperAirplaneIcon, { className: "w-5 h-5" }))))),
            react_2.default.createElement("div", { className: "pt-4 border-t border-gray-200" },
                react_2.default.createElement("button", { type: "button", onClick: function () { return onNavigate(routes_1.ROUTES.ADMIN_NEWSLETTER); }, className: "w-full px-4 py-2 mb-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50" }, "Retour \u00E0 la liste"),
                onDelete && (react_2.default.createElement("button", { className: "w-full px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700 ".concat(isSaving ? 'opacity-50 cursor-not-allowed' : ''), onClick: onDelete, disabled: isSaving }, "Supprimer la newsletter")))),
        react_2.default.createElement(react_1.Dialog, { open: showSaveConfirmation, onClose: function () { return setShowSaveConfirmation(false); }, className: "relative z-50" },
            react_2.default.createElement("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }),
            react_2.default.createElement("div", { className: "fixed inset-0 flex items-center justify-center p-4" },
                react_2.default.createElement(react_1.Dialog.Panel, { className: "mx-auto max-w-md rounded bg-white p-6" },
                    react_2.default.createElement(react_1.Dialog.Title, { className: "text-lg font-medium text-gray-900 mb-4" }, "Confirmer la sauvegarde"),
                    react_2.default.createElement("div", { className: "mb-6" },
                        react_2.default.createElement("p", { className: "text-sm text-gray-500" }, "Apr\u00E8s la premi\u00E8re sauvegarde, vous ne pourrez plus :"),
                        react_2.default.createElement("ul", { className: "mt-2 text-sm text-gray-500 list-disc list-inside" },
                            react_2.default.createElement("li", null, "Modifier la s\u00E9lection des articles de blog"),
                            react_2.default.createElement("li", null, "Changer de template"),
                            react_2.default.createElement("li", null, "Utiliser l'IA pour g\u00E9n\u00E9rer le contenu")),
                        react_2.default.createElement("p", { className: "mt-4 text-sm text-gray-500" }, "\u00CAtes-vous s\u00FBr de vouloir continuer ?")),
                    react_2.default.createElement("div", { className: "flex justify-end gap-3" },
                        react_2.default.createElement("button", { type: "button", className: "inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2", onClick: function () { return setShowSaveConfirmation(false); } }, "Annuler"),
                        react_2.default.createElement("button", { type: "button", className: "inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2", onClick: function () {
                                setShowSaveConfirmation(false);
                                onUpdate({
                                    subject: newsletter.subject,
                                    content: newsletter.content,
                                    status: newsletter.status,
                                });
                            } }, "Confirmer")))))));
}
