"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupStepOrder = void 0;
exports.setupStepOrder = [
    'coproValidation',
    'addressValidation',
    'addMissingCopros',
    'surfaceValidation',
    'collaboratorAttribution',
    'mailAlertSetup',
    'vigilanceAdditionalCopro',
];
