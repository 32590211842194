"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = BlogPostSidebar;
var outline_1 = require("@heroicons/react/24/outline");
var flowbite_react_1 = require("flowbite-react");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var formatters_1 = require("../../../../../../common-utils/formatters");
var BlogPost_1 = require("../../../../../@types/BlogPost");
var JWTHeader_1 = require("../../../../../utils/JWTHeader");
var routes_1 = require("../../../../../utils/routes");
var Notification_1 = require("../../../../common/Notification");
function BlogPostSidebar(_a) {
    var _this = this;
    var blogPost = _a.blogPost, onUpdate = _a.onUpdate, isSaving = _a.isSaving, lastSaved = _a.lastSaved, onAIContentGenerated = _a.onAIContentGenerated;
    var _b = __read((0, react_1.useState)(blogPost.title), 2), title = _b[0], setTitle = _b[1];
    var fileInputRef = (0, react_1.useRef)(null);
    var navigate = (0, react_router_dom_1.useNavigate)();
    var jwt = (0, react_redux_1.useSelector)(function (state) { return state.auth.token; });
    var _c = __read((0, react_1.useState)(''), 2), aiPrompt = _c[0], setAiPrompt = _c[1];
    var _d = __read((0, react_1.useState)(false), 2), isGenerating = _d[0], setIsGenerating = _d[1];
    var handleSave = function () {
        var formData = new FormData();
        formData.append('title', title);
        formData.append('content', blogPost.content);
        formData.append('status', blogPost.status);
        onUpdate(formData);
    };
    var handleStatusChange = function (newStatus) {
        var formData = new FormData();
        formData.append('title', title);
        formData.append('content', blogPost.content);
        formData.append('status', newStatus);
        onUpdate(formData);
    };
    var handleTitleBlur = function () {
        if (title !== blogPost.title) {
            var formData = new FormData();
            formData.append('title', title);
            formData.append('content', blogPost.content);
            formData.append('status', blogPost.status);
            onUpdate(formData);
        }
    };
    var handleImageChange = function (event) { return __awaiter(_this, void 0, void 0, function () {
        var file, formData;
        var _a;
        return __generator(this, function (_b) {
            file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
            if (!file) {
                return [2 /*return*/];
            }
            formData = new FormData();
            formData.append('title', title);
            formData.append('content', blogPost.content);
            formData.append('status', blogPost.status);
            formData.append('mainImageFile', file);
            onUpdate(formData);
            return [2 /*return*/];
        });
    }); };
    var handleDelete = function () { return __awaiter(_this, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cet article ?')) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetch("/api/blog-posts/".concat(blogPost.id), __assign({ method: 'DELETE' }, (0, JWTHeader_1.JWTHeader)(jwt)))];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to delete blog post');
                    }
                    (0, Notification_1.showToast)('success', 'Article supprimé.');
                    navigate(routes_1.ROUTES.ADMIN_BLOG_POSTS);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error('Failed to delete blog post:', error_1);
                    (0, Notification_1.showToast)('error', 'Une erreur est survenue lors de la suppression.');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleAIGeneration = function () { return __awaiter(_this, void 0, void 0, function () {
        var response, data, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!aiPrompt || !jwt) {
                        (0, Notification_1.showToast)('error', 'Veuillez entrer un prompt pour la génération.');
                        return [2 /*return*/];
                    }
                    setIsGenerating(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    return [4 /*yield*/, fetch('/api/blog-posts/generate-content', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: (0, JWTHeader_1.JWTAuthorization)(jwt),
                            },
                            body: JSON.stringify({
                                prompt: aiPrompt,
                            }),
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Erreur lors de la génération du contenu');
                    }
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _a.sent();
                    if (onAIContentGenerated) {
                        onAIContentGenerated(data);
                        setTitle(data.title);
                    }
                    (0, Notification_1.showToast)('success', 'Contenu généré avec succès.');
                    return [3 /*break*/, 6];
                case 4:
                    error_2 = _a.sent();
                    console.error('Erreur:', error_2);
                    (0, Notification_1.showToast)('error', 'Erreur lors de la génération du contenu.');
                    return [3 /*break*/, 6];
                case 5:
                    setIsGenerating(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement("div", { className: "w-full lg:w-80 bg-white lg:h-full rounded-lg p-2 overflow-y-auto" },
        react_1.default.createElement("div", { className: "space-y-6 bg-rgpd-white p-4" },
            react_1.default.createElement("div", null,
                react_1.default.createElement("h2", { className: "text-lg font-semibold mb-2" }, "Param\u00E8tres de l'article"),
                react_1.default.createElement("div", { className: "flex items-center text-sm text-gray-500" }, isSaving ? (react_1.default.createElement("div", { className: "flex items-center" },
                    react_1.default.createElement(flowbite_react_1.Spinner, { size: "sm", className: "mr-2" }),
                    "Sauvegarde en cours...")) : lastSaved && (react_1.default.createElement(react_1.default.Fragment, null,
                    "Derni\u00E8re sauvegarde : ",
                    (0, formatters_1.formatDateWithTime)(lastSaved.toISOString()))))),
            react_1.default.createElement("button", { onClick: handleSave, disabled: isSaving, className: "w-full px-2 py-2 text-white bg-rgpd-green rounded-md hover:bg-rgpd-green-dark transition-colors flex items-center justify-center ".concat(isSaving ? 'opacity-50 cursor-not-allowed' : '') }, isSaving ? (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(flowbite_react_1.Spinner, { size: "sm", className: "mr-2" }),
                "Sauvegarde...")) : ('Sauvegarder')),
            react_1.default.createElement("div", { className: "space-y-2" },
                react_1.default.createElement("label", { className: "text-sm font-medium" }, "Titre"),
                react_1.default.createElement("input", { type: "text", value: title, onChange: function (e) { return setTitle(e.target.value); }, onBlur: handleTitleBlur, className: "w-full p-2 border rounded-md", disabled: isSaving })),
            react_1.default.createElement("div", { className: "space-y-2" },
                react_1.default.createElement("label", { className: "text-sm font-medium" }, "Statut"),
                react_1.default.createElement("select", { value: blogPost.status, onChange: function (e) { return handleStatusChange(e.target.value); }, className: "w-full p-2 border rounded-md", disabled: isSaving },
                    react_1.default.createElement("option", { value: BlogPost_1.BlogPostStatusEnum.DRAFT }, "Brouillon"),
                    react_1.default.createElement("option", { value: BlogPost_1.BlogPostStatusEnum.PUBLIC }, "Public"))),
            react_1.default.createElement("div", { className: "space-y-2" },
                react_1.default.createElement("label", { className: "text-sm font-medium" }, "Image principale"),
                blogPost.mainImageName && (react_1.default.createElement("div", { className: "bg-gray-100 rounded-lg mb-2 flex items-center justify-center h-40" },
                    react_1.default.createElement("img", { src: "/uploads/blog-posts/".concat(blogPost.mainImageName), alt: "Preview", className: "max-w-full max-h-40 object-contain" }))),
                react_1.default.createElement("input", { type: "file", ref: fileInputRef, className: "hidden", accept: "image/jpeg,image/png,image/webp", onChange: handleImageChange, disabled: isSaving }),
                react_1.default.createElement("button", { className: "w-full px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50 ".concat(isSaving ? 'opacity-50 cursor-not-allowed' : ''), onClick: function () { var _a; return (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); }, disabled: isSaving }, isSaving ? (react_1.default.createElement("div", { className: "flex items-center justify-center" },
                    react_1.default.createElement(flowbite_react_1.Spinner, { size: "sm", className: "mr-2" }),
                    "Chargement...")) : ('Changer l\'image'))),
            react_1.default.createElement("div", { className: "space-y-2 mb-4" },
                react_1.default.createElement("h3", { className: "font-semibold mb-2" }, "G\u00E9n\u00E9ration IA"),
                react_1.default.createElement("div", { className: "space-y-2" },
                    react_1.default.createElement("textarea", { value: aiPrompt, onChange: function (e) { return setAiPrompt(e.target.value); }, placeholder: "D\u00E9crivez le contenu que vous souhaitez g\u00E9n\u00E9rer...", className: "w-full p-2 border rounded-md h-24", disabled: isGenerating }),
                    react_1.default.createElement("button", { onClick: handleAIGeneration, disabled: isGenerating || !aiPrompt, className: "w-full px-4 py-2 text-white bg-purple-600 rounded-md hover:bg-purple-700 flex items-center justify-center ".concat(isGenerating ? 'opacity-50 cursor-not-allowed' : '') }, isGenerating ? (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(flowbite_react_1.Spinner, { size: "sm", className: "mr-2" }),
                        "G\u00E9n\u00E9ration en cours...")) : (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(outline_1.SparklesIcon, { className: "w-5 h-5 mr-2" }),
                        "G\u00E9n\u00E9rer le contenu"))))),
            react_1.default.createElement("div", { className: "pt-4 border-t border-gray-200" },
                react_1.default.createElement("button", { type: "button", onClick: function () { return navigate(routes_1.ROUTES.ADMIN_BLOG_POSTS); }, className: "w-full px-4 py-2 mb-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50" }, "Retour \u00E0 la liste"),
                react_1.default.createElement("button", { className: "w-full px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700 ".concat(isSaving ? 'opacity-50 cursor-not-allowed' : ''), onClick: handleDelete, disabled: isSaving }, "Supprimer l'article")))));
}
