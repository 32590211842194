"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setRealSales = exports.setPricePreSaleStatements = exports.setNbPreSaleStatements = exports.setNbMandates = exports.setNbSaleStatements = exports.setSalesData = exports.setYears = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    years: [],
    nbSaleStatements: {},
    nbMandates: {},
    nbPreSaleStatements: {},
    pricePreSaleStatements: { value: '' },
    realSales: {},
    salesData: {},
};
var salesDataSlice = (0, toolkit_1.createSlice)({
    name: 'salesData',
    initialState: initialState,
    reducers: {
        setYears: function (state, action) {
            state.years = action.payload;
        },
        setSalesData: function (state, action) {
            state.salesData = action.payload;
        },
        setNbSaleStatements: function (state, action) {
            state.nbSaleStatements = action.payload;
        },
        setNbMandates: function (state, action) {
            state.nbMandates = action.payload;
        },
        setNbPreSaleStatements: function (state, action) {
            state.nbPreSaleStatements = action.payload;
        },
        setPricePreSaleStatements: function (state, action) {
            state.pricePreSaleStatements = action.payload;
        },
        setRealSales: function (state, action) {
            state.realSales = action.payload;
        },
    },
});
exports.setYears = (_a = salesDataSlice.actions, _a.setYears), exports.setSalesData = _a.setSalesData, exports.setNbSaleStatements = _a.setNbSaleStatements, exports.setNbMandates = _a.setNbMandates, exports.setNbPreSaleStatements = _a.setNbPreSaleStatements, exports.setPricePreSaleStatements = _a.setPricePreSaleStatements, exports.setRealSales = _a.setRealSales;
exports.default = salesDataSlice.reducer;
