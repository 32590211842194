"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var solid_1 = require("@heroicons/react/24/solid");
var react_1 = __importStar(require("react"));
var fetchAutocompleteResults = function (query, searchHouseNumberOnly) { return __awaiter(void 0, void 0, void 0, function () {
    var response, data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, fetch("https://api-adresse.data.gouv.fr/search/?q=".concat(encodeURIComponent(query), "&limit=5").concat(searchHouseNumberOnly ? '&type=housenumber' : ''))];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data.features];
            case 3:
                error_1 = _a.sent();
                console.error('Error fetching autocomplete results:', error_1);
                return [2 /*return*/, []];
            case 4: return [2 /*return*/];
        }
    });
}); };
var BanSearch = function (_a) {
    var onSelectResult = _a.onSelectResult, externalSearchQuery = _a.searchQuery, resetExternalSearchQuery = _a.resetSearchQuery, _b = _a.searchHouseNumberOnly, searchHouseNumberOnly = _b === void 0 ? false : _b;
    var _c = __read((0, react_1.useState)(''), 2), internalSearchQuery = _c[0], setInternalSearchQuery = _c[1];
    var _d = __read((0, react_1.useState)([]), 2), searchResults = _d[0], setSearchResults = _d[1];
    var _e = __read((0, react_1.useState)(''), 2), lastSuggestion = _e[0], setLastSuggestion = _e[1];
    var handleSearch = (0, react_1.useCallback)(function (e) {
        var query = e.target.value;
        setInternalSearchQuery(query);
    }, []);
    var updateSearchResults = (0, react_1.useCallback)(function (query) { return __awaiter(void 0, void 0, void 0, function () {
        var results;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(query.length > 2 && query !== lastSuggestion)) return [3 /*break*/, 2];
                    return [4 /*yield*/, fetchAutocompleteResults(query, searchHouseNumberOnly)];
                case 1:
                    results = _a.sent();
                    setSearchResults(results);
                    return [3 /*break*/, 3];
                case 2:
                    if (query.length <= 2) {
                        setSearchResults([]);
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [lastSuggestion, searchHouseNumberOnly]);
    var handleSelectAddress = (0, react_1.useCallback)(function (result) {
        var selectedAddress = result.properties.label;
        setInternalSearchQuery(selectedAddress);
        setSearchResults([]);
        setLastSuggestion(selectedAddress);
        onSelectResult(result);
    }, [onSelectResult]);
    (0, react_1.useEffect)(function () {
        if (internalSearchQuery !== '' && internalSearchQuery !== lastSuggestion) {
            var timeoutId_1 = setTimeout(function () {
                updateSearchResults(internalSearchQuery);
            }, 300); // Add a debounce of 300ms
            return function () { return clearTimeout(timeoutId_1); };
        }
    }, [internalSearchQuery, lastSuggestion, updateSearchResults]);
    // This effect handles external changes to searchQuery (e.g., "Utiliser dans la recherche")
    (0, react_1.useEffect)(function () {
        if (externalSearchQuery != undefined
            && externalSearchQuery != ''
            && resetExternalSearchQuery
            && externalSearchQuery != internalSearchQuery) {
            setInternalSearchQuery(externalSearchQuery);
            resetExternalSearchQuery('');
        }
    }, [externalSearchQuery, setInternalSearchQuery]);
    var shouldShowSuggestions = searchResults.length > 0 && internalSearchQuery !== lastSuggestion;
    return (react_1.default.createElement("div", { className: "relative" },
        react_1.default.createElement("input", { type: "text", value: internalSearchQuery, onChange: handleSearch, placeholder: "Rechercher une adresse...", className: "w-full p-2 pr-10 border border-gray-300 rounded", autoComplete: "nope" }),
        react_1.default.createElement(solid_1.MagnifyingGlassIcon, { className: "h-5 w-5 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" }),
        shouldShowSuggestions && (react_1.default.createElement("ul", { className: "absolute z-[999] w-full bg-white border border-gray-300 rounded mt-1" }, searchResults.map(function (result, index) { return (react_1.default.createElement("li", { key: index, onClick: function () { return handleSelectAddress(result); }, className: "p-2 hover:bg-gray-100 cursor-pointer" }, result.properties.label)); })))));
};
exports.default = BanSearch;
