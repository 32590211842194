"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatToText = exports.formatDateStr = exports.formatDateWithTime = exports.formatDate = exports.ShortMonths = exports.Months = exports.formatNumberWithSpaces = void 0;
exports.formatCurrency = formatCurrency;
exports.formatSurface = formatSurface;
exports.formatValue = formatValue;
function formatCurrency(input, currency) {
    var _a;
    if (currency === void 0) { currency = '€'; }
    var parts = input.toFixed(2).split('.');
    var integerPart = (_a = __spreadArray([], __read(parts[0]), false).reverse().join('').match(/.{1,3}/g)) === null || _a === void 0 ? void 0 : _a.join(' ');
    return "".concat(integerPart ? __spreadArray([], __read(integerPart), false).reverse().join('') : '').concat(currency); // Without decimal
}
var formatNumberWithSpaces = function (value) { return value.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ' '); };
exports.formatNumberWithSpaces = formatNumberWithSpaces;
function formatSurface(input, decimals) {
    if (decimals === void 0) { decimals = 0; }
    return "".concat(input.toFixed(decimals).replaceAll(/\d(?=(\d{3})+$)/g, '$& ').replace('.', ','), "m\u00B2");
}
function formatValue(input, decimals) {
    if (decimals === void 0) { decimals = 0; }
    var parsedInput = parseFloat(input);
    if (isNaN(parsedInput)) {
        return '0';
    }
    // &nbsp to not break the line
    return parsedInput.toFixed(decimals).replaceAll(/\d(?=(\d{3})+$)/g, '$& ').replace('.', ',');
}
// Date
exports.Months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
];
exports.ShortMonths = [
    'Janv.', 'Févr.', 'Mars', 'Avr.', 'Mai', 'Juin',
    'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.',
];
var formatDate = function (dateString) {
    var dateObject = new Date(dateString);
    return "".concat(dateObject.getDate().toString().padStart(2, '0'), "/").concat((dateObject.getMonth() + 1).toString().padStart(2, '0'), "/").concat(dateObject.getFullYear());
};
exports.formatDate = formatDate;
var formatDateWithTime = function (dateString) {
    var dateObject = new Date(dateString);
    return "".concat(dateObject.getDate().toString().padStart(2, '0'), "/").concat((dateObject.getMonth() + 1).toString().padStart(2, '0'), "/").concat(dateObject.getFullYear(), " \u00E0 ").concat(dateObject.getHours().toString().padStart(2, '0'), ":").concat(dateObject.getMinutes().toString().padStart(2, '0'));
};
exports.formatDateWithTime = formatDateWithTime;
var formatDateStr = function (date) {
    var dateObject = new Date(date);
    return "".concat(dateObject.getDate(), " ").concat(exports.Months[dateObject.getMonth()], " ").concat(dateObject.getFullYear());
};
exports.formatDateStr = formatDateStr;
// Function to extract text content from a potential HTML-like object
var formatToText = function (elem) {
    if (elem == null) {
        return '';
    }
    // Handle string or number
    if (typeof elem === 'string' || typeof elem === 'number') {
        return elem.toString();
    }
    // Handle arrays
    if (Array.isArray(elem)) {
        return elem.map(exports.formatToText).join('');
    }
    if (typeof elem === 'object' && 'props' in elem) {
        // Check if the element looks like a SelectMe component
        if ('options' in elem.props && 'value' in elem.props) {
            var value = elem.props.value;
            // Handle multiple and single select cases
            if (Array.isArray(value)) {
                return value.map(function (v) { return v.label || v; }).join(', ');
            }
            if (value && typeof value === 'object') {
                return value.label || '';
            }
            return value ? value.toString() : '';
        }
        var children = elem.props.children;
        if (children) {
            return (0, exports.formatToText)(children);
        }
    }
    // Handle DOM elements
    if (elem instanceof Element) {
        return elem.textContent || '';
    }
    // Handle other cases (like boolean values)
    return '';
};
exports.formatToText = formatToText;
