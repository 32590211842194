"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROUTES = void 0;
exports.ROUTES = {
    HOME: '/',
    BLOCKED_ACCESS: '/blocked-access',
    LOGOUT: '/logout',
    // Securing
    SECURING_DASHBOARD: '/securing',
    SETUP_VERIFY_COPROPRIETES: '/securing/verify-coproprietes',
    SETUP_ADD_ADDRESSES: '/securing/add-addresses',
    SETUP_ADD_COPROPRIETES: '/securing/add-coproprietes',
    SETUP_COPRO_AREA: '/securing/copro-area',
    SETUP_ADD_COLLABORATORS: '/securing/add-collaborators',
    SETUP_CONFIGURE_NOTIFICATIONS: '/securing/configure-notifications',
    SETUP_DIFF_IMPORTED_RNIC: '/securing/diff-imported-rnic/',
    SETUP_DIFF_IMPORTED_RNIC_FILE_ID: '/securing/diff-imported-rnic/:fileId',
    // Analyzing
    ANALYZING_DASHBOARD: '/analyzing',
    ANALYZING_MARKET_VISION: '/analyzing/market-vision',
    ANALYZING_DPE_VISION: '/analyzing/dpe-vision',
    // Prospecting
    // -- Incoming
    // ---- Transaction
    PROSPECTING_INCOMING_TRANSACTION: '/incoming-transaction',
    PROSPECTING_INCOMING_TRANSACTION_MAIL_MODELS: '/incoming-transaction/mail-models',
    PROSPECTING_INCOMING_TRANSACTION_GENERATE_COPRO_REPORT: '/incoming-transaction/generate-copro-report',
    // ---- Rent
    PROSPECTING_INCOMING_RENT: '/incoming-rent',
    PROSPECTING_INCOMING_RENT_MAIL_MODELS: '/incoming-rent/mail-models',
    PROSPECTING_INCOMING_RENT_GENERATE_COPRO_REPORT: '/incoming-rent/generate-copro-report',
    // -- Direct
    // ---- All new DPEs
    PROSPECTING_DIRECT_ALL_NEW_DPE: '/all-new-dpes',
    PROSPECTING_DIRECT_ALL_NEW_DPE_NEW_OPPORTUNITIES: '/all-new-dpes/new-opportunities',
    PROSPECTING_DIRECT_ALL_NEW_DPE_IDENTIFIED_OWNER: '/all-new-dpes/identified-owner',
    PROSPECTING_DIRECT_ALL_NEW_DPE_COMMUNICATE: '/all-new-dpes/communicate',
    PROSPECTING_DIRECT_ALL_NEW_DPE_TEAM_PERFORMANCE: '/all-new-dpes/team-performance',
    // ---- Transaction
    PROSPECTING_DIRECT_TRANSACTION: '/direct-transaction',
    PROSPECTING_DIRECT_TRANSACTION_MOOK_FORMATION: '/direct-transaction/mook-formation',
    PROSPECTING_DIRECT_TRANSACTION_PHONE_PROMPT: '/direct-transaction/phone-prompt',
    PROSPECTING_DIRECT_TRANSACTION_MAIL_MODELS: '/direct-transaction/mail-models',
    PROSPECTING_DIRECT_TRANSACTION_GENERATE_DPE_REPORT: '/direct-transaction/generate-dpe-report',
    PROSPECTING_DIRECT_TRANSACTION_GENERATE_COPRO_REPORT: '/direct-transaction/generate-copro-report',
    PROSPECTING_DIRECT_TRANSACTION_GENERATE_SALES_REPORT: '/direct-transaction/generate-sales-report',
    // ---- Rent
    PROSPECTING_DIRECT_RENT: '/direct-rent',
    PROSPECTING_DIRECT_RENT_MOOK_FORMATION: '/direct-rent/mook-formation',
    PROSPECTING_DIRECT_RENT_PHONE_PROMPT: '/direct-rent/phone-prompt',
    PROSPECTING_DIRECT_RENT_MAIL_MODELS: '/direct-rent/mail-models',
    PROSPECTING_DIRECT_RENT_SCIS: '/direct-rent/scis',
    PROSPECTING_DIRECT_RENT_SCIS_SIREN: '/direct-rent/scis/:siren',
    // Monitoring
    MONITORING_DASHBOARD: '/monitoring',
    MONITORING_BAUX_COMMERCIAUX: '/monitoring/baux-commerciaux',
    MONITORING_BAUX_A_ECHEANCE: '/monitoring/baux-a-echeance',
    MONITORING_ENTREPRISES_EN_DEFAILLANCE: '/monitoring/entreprises-en-defaillance',
    MONITORING_ENTREPRISES_SUIVIES: '/monitoring/entreprises-suivies',
    // Quick Access
    QUICK_ACCESS_COPRO_MANAGING: '/quick-access/copro-managing',
    QUICK_ACCESS_COPRO_MANAGING_ID: '/quick-access/copro-managing/:coproId',
    QUICK_ACCESS_COPRO_VIGILANCE: '/quick-access/copro-vigilance',
    QUICK_ACCESS_GENERAL_ASSEMBLY: '/quick-access/general-assembly',
    QUICK_ACCESS_GESTIONNAIRES_COPROS: '/quick-access/gestionnaires-copros',
    // Communication
    COMMUNICATION_DASHBOARD: '/communication',
    COMMUNICATION_NEWSLETTERS: '/communication/newsletters',
    COMMUNICATION_NEWSLETTER_PREVIEW: '/communication/newsletters/:id',
    COMMUNICATION_PAGE_SITE: '/communication/page-site',
    COMMUNICATION_SCRIPT_APPEL: '/communication/script-appel',
    // Admin
    ADMIN_IMPORT: '/admin/import',
    ADMIN_DIFF: '/admin/diff',
    ADMIN_COMPANY_VERIFICATION: '/admin/company-verification',
    ADMIN_COPRO_VALIDATION: '/admin/copro-validation/*',
    ADMIN_COMPANIES_LIST: '/admin/companies',
    ADMIN_COMPANY_DETAILS: '/admin/companies/:companyId',
    ADMIN_SIRET_TRACKED_IMPORT: '/admin/siret-tracked-import',
    ADMIN_BLOG_POSTS: '/admin/blog-posts',
    ADMIN_BLOG_POST_NEW: '/admin/blog-posts/new',
    ADMIN_BLOG_POST_EDIT: '/admin/blog-posts/:id/edit',
    ADMIN_NEWSLETTER: '/admin/newsletter',
    ADMIN_NEWSLETTER_NEW: '/admin/newsletter/new',
    ADMIN_NEWSLETTER_EDIT: '/admin/newsletter/:id/edit',
    // External
    EXTERNAL_BLOG: '/blog',
    EXTERNAL_BLOG_POST: '/blog/:slug',
};
