"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleFormSubmit = exports.handleContactClick = exports.fetchDpesData = void 0;
var formatters_1 = require("../../../../../../common-utils/formatters");
var DPE_1 = require("../../../../../@types/DPE");
var JWTHeader_1 = require("../../../../../utils/JWTHeader");
var Notification_1 = require("../../../../common/Notification");
var fetchDpesData = function (companyId_1, jwt_1, monthFiltered_1) {
    var args_1 = [];
    for (var _i = 3; _i < arguments.length; _i++) {
        args_1[_i - 3] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([companyId_1, jwt_1, monthFiltered_1], __read(args_1), false), void 0, function (companyId, jwt, monthFiltered, onlyTreated) {
        var fetchDpes, dpesInProcess, dpesProcessed, i, i, allDpes;
        if (onlyTreated === void 0) { onlyTreated = false; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fetchDpes = function (step) { return __awaiter(void 0, void 0, void 0, function () {
                        var apiUrl, response, result;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (monthFiltered && monthFiltered.type === 'reception' && monthFiltered.date) {
                                        apiUrl = "/api/companies/".concat(companyId, "/dpes?step=1&limit=10000000&filterDPEStep=").concat(step, "&filterMonthCreatedAt=").concat(monthFiltered.date);
                                    }
                                    else if (monthFiltered && monthFiltered.type === 'etablissement' && monthFiltered.date) {
                                        apiUrl = "/api/companies/".concat(companyId, "/dpes?step=1&limit=10000000&filterDPEStep=").concat(step, "&filterMonthEstablishedAt=").concat(monthFiltered.date);
                                    }
                                    else {
                                        // standard fetch no filtering
                                        apiUrl = "/api/companies/".concat(companyId, "/dpes?step=1&limit=10000000&filterDPEStep=").concat(step);
                                    }
                                    return [4 /*yield*/, fetch(apiUrl, (0, JWTHeader_1.JWTHeader)(jwt))];
                                case 1:
                                    response = _a.sent();
                                    return [4 /*yield*/, response.json()];
                                case 2:
                                    result = _a.sent();
                                    return [2 /*return*/, result.results];
                            }
                        });
                    }); };
                    dpesInProcess = [];
                    dpesProcessed = [];
                    if (!onlyTreated) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetchDpes(DPE_1.DpeStepsEnum.IN_PROCESS)];
                case 1:
                    dpesInProcess = _a.sent();
                    // filter out the owners that don't have an interaction
                    for (i = 0; i < dpesInProcess.length; i++) {
                        dpesInProcess[i].owners = dpesInProcess[i].owners.filter(function (owner) { return owner.interaction; });
                    }
                    // if the dpe has no more owners inside, remove it from the list
                    dpesInProcess = dpesInProcess.filter(function (dpe) { return dpe.owners.length > 0; });
                    return [4 /*yield*/, fetchDpes(DPE_1.DpeStepsEnum.PROCESSED)];
                case 2:
                    dpesProcessed = _a.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, fetchDpes(DPE_1.DpeStepsEnum.IN_PROCESS)];
                case 4:
                    dpesInProcess = _a.sent();
                    // filter out the owners that have an interaction
                    for (i = 0; i < dpesInProcess.length; i++) {
                        dpesInProcess[i].owners = dpesInProcess[i].owners.filter(function (owner) { return !owner.interaction; });
                    }
                    _a.label = 5;
                case 5:
                    allDpes = onlyTreated ? __spreadArray(__spreadArray([], __read(dpesInProcess), false), __read(dpesProcessed), false) : dpesInProcess;
                    // Process the results
                    return [2 /*return*/, allDpes.map(function (dpe) {
                            // Filter owners based on onlyTreated flag
                            var owners = dpe.owners
                                .map(function (owner) { return ({
                                id: owner.id,
                                firstname: owner.firstname,
                                lastname: owner.lastname,
                                address: owner.address,
                                livingIn: owner.livingIn,
                                phone: owner.phone,
                                email: owner.email,
                                contacts: owner.contacts,
                                interaction: owner.interaction,
                                showPhone: false, // Add a flag to show/hide phone number
                                civility: owner.civility,
                                floorNumber: owner.floorNumber,
                                doorNumber: owner.doorNumber,
                                postalCode: owner.postalCode,
                                city: owner.city,
                                surface: owner.surface,
                            }); });
                            // Return the processed DPE data
                            return {
                                id: dpe.id,
                                coproId: dpe.copro.id,
                                coproName: dpe.copro.name,
                                coproAddress: dpe.details.banLabel,
                                assistant: "".concat(dpe.treatedBy.firstname, " ").concat(dpe.treatedBy.lastname ? dpe.treatedBy.lastname.toUpperCase() : ''),
                                value: dpe.details.classeBilanDpe ? dpe.details.classeBilanDpe : 'Non renseigné',
                                rawDate: dpe.details.dateReceptionDpe,
                                dateDPE: "".concat(new Date(dpe.details.dateReceptionDpe).toLocaleDateString()),
                                rawSurface: dpe.details.surfaceHabitableLogement,
                                surfaceDPE: dpe.details.surfaceHabitableLogement ? (0, formatters_1.formatSurface)(Number(dpe.details.surfaceHabitableLogement)) : 'Non renseignée',
                                floorDPE: dpe.details.complEtageAppartement,
                                infoDPE: dpe.details.complRefLogement ? dpe.details.complRefLogement : '',
                                owners: owners,
                                details: dpe.details,
                            };
                        })];
            }
        });
    });
};
exports.fetchDpesData = fetchDpesData;
var handleContactClick = function (ownerId, type, jwt) { return __awaiter(void 0, void 0, void 0, function () {
    var response, data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, fetch("/api/owners/".concat(ownerId, "/contacts"), {
                        method: 'POST',
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: (0, JWTHeader_1.JWTAuthorization)(jwt),
                        },
                        body: JSON.stringify({ type: type }),
                    })];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    console.error('Error creating contact:', response.statusText);
                    (0, Notification_1.showToast)('error', 'Une erreur est survenue lors de la mise à jour des données.');
                    return [2 /*return*/];
                }
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                (0, Notification_1.showToast)('success', 'Prise de contact effectuée avec succès.');
                return [2 /*return*/, data];
            case 3:
                error_1 = _a.sent();
                console.error('Error creating contact:', error_1);
                (0, Notification_1.showToast)('error', 'Une erreur est survenue lors de la mise à jour des données.');
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
exports.handleContactClick = handleContactClick;
var handleFormSubmit = function (ownerId, dpe, interactionForm, jwt) { return __awaiter(void 0, void 0, void 0, function () {
    var allOwnersHaveInteraction;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch("/api/owners/".concat(ownerId, "/interactions"), {
                    method: 'POST',
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: (0, JWTHeader_1.JWTAuthorization)(jwt),
                    },
                    body: JSON.stringify(interactionForm),
                }).then(function () {
                    (0, Notification_1.showToast)('success', 'Le propriétaire a été traité.');
                }).catch(function (error) {
                    console.error('Error creating interaction:', error);
                    (0, Notification_1.showToast)('error', 'Une erreur est survenue lors de la mise à jour des données.');
                })];
            case 1:
                _a.sent();
                allOwnersHaveInteraction = dpe.owners.every(function (owner) { return owner.interaction || owner.id === ownerId; });
                if (!(allOwnersHaveInteraction || interactionForm.dpeInitiator == 1)) return [3 /*break*/, 3];
                return [4 /*yield*/, fetch("/api/dpes/".concat(dpe.id, "/step/").concat(DPE_1.DpeStepsEnum.PROCESSED), {
                        method: 'PATCH',
                        headers: {
                            Authorization: (0, JWTHeader_1.JWTAuthorization)(jwt),
                            'Content-Type': 'application/json',
                        },
                    }).catch(function (error) {
                        console.error('Error next step dpe:', error);
                        (0, Notification_1.showToast)('error', 'Une erreur est survenue lors de la mise à jour des données.');
                    })];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.handleFormSubmit = handleFormSubmit;
