"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROUTE_TITLES = void 0;
var routes_1 = require("./routes");
exports.ROUTE_TITLES = (_a = {},
    _a[routes_1.ROUTES.HOME] = 'Tableau de bord',
    _a[routes_1.ROUTES.BLOCKED_ACCESS] = 'Accès bloqué',
    _a[routes_1.ROUTES.LOGOUT] = 'Déconnexion',
    // Securing
    _a[routes_1.ROUTES.SECURING_DASHBOARD] = 'Sécurisation',
    _a[routes_1.ROUTES.SETUP_VERIFY_COPROPRIETES] = 'Validation des copropriétés',
    _a[routes_1.ROUTES.SETUP_ADD_ADDRESSES] = 'Vérification des adresses',
    _a[routes_1.ROUTES.SETUP_ADD_COPROPRIETES] = 'Ajout des copropriétés',
    _a[routes_1.ROUTES.SETUP_COPRO_AREA] = 'Surface des copropriétés',
    _a[routes_1.ROUTES.SETUP_ADD_COLLABORATORS] = 'Attribution des collaborateurs',
    _a[routes_1.ROUTES.SETUP_CONFIGURE_NOTIFICATIONS] = 'Configuration des alertes',
    _a[routes_1.ROUTES.SETUP_DIFF_IMPORTED_RNIC] = 'Anomalies détectées suite import',
    // Analyzing
    _a[routes_1.ROUTES.ANALYZING_DASHBOARD] = 'Analyse',
    _a[routes_1.ROUTES.ANALYZING_MARKET_VISION] = 'Vision du marché',
    _a[routes_1.ROUTES.ANALYZING_DPE_VISION] = 'Vision des DPEs',
    // Prospecting
    // --  Incoming
    // ---- Transaction
    _a[routes_1.ROUTES.PROSPECTING_INCOMING_TRANSACTION] = 'Transactions',
    _a[routes_1.ROUTES.PROSPECTING_INCOMING_TRANSACTION_MAIL_MODELS] = 'Modèles de mail',
    _a[routes_1.ROUTES.PROSPECTING_INCOMING_TRANSACTION_GENERATE_COPRO_REPORT] = 'Rapport de copropriété',
    // ---- Rent
    _a[routes_1.ROUTES.PROSPECTING_INCOMING_RENT] = 'Locations',
    _a[routes_1.ROUTES.PROSPECTING_INCOMING_RENT_MAIL_MODELS] = 'Modèles de mail',
    _a[routes_1.ROUTES.PROSPECTING_INCOMING_RENT_GENERATE_COPRO_REPORT] = 'Rapport de copropriété',
    // -- Direct
    // ---- All new DPEs
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_ALL_NEW_DPE] = 'Tous les nouveaux DPEs',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_ALL_NEW_DPE_NEW_OPPORTUNITIES] = 'Nouvelles opportunités (DPE)',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_ALL_NEW_DPE_IDENTIFIED_OWNER] = 'Propriétaires identifiés',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_ALL_NEW_DPE_COMMUNICATE] = 'DPE en cours de traitement',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_ALL_NEW_DPE_TEAM_PERFORMANCE] = 'Suivi de la performance',
    // ---- Transaction
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION] = 'Transactions',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION_MOOK_FORMATION] = 'Nouvelles opportunités (DPE)',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION_PHONE_PROMPT] = 'Propriétaires identifiés',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION_MAIL_MODELS] = 'DPE en cours de traitement',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION_GENERATE_DPE_REPORT] = 'Rapport des DPEs',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION_GENERATE_COPRO_REPORT] = 'Rapport de copropriété',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION_GENERATE_SALES_REPORT] = 'Rapport des ventes',
    // ---- Rent
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_RENT] = 'Locations',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_RENT_MOOK_FORMATION] = 'Nouvelles opportunités (DPE)',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_RENT_PHONE_PROMPT] = 'Propriétaires identifiés',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_RENT_MAIL_MODELS] = 'DPE en cours de traitement',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_RENT_SCIS] = 'SCIs',
    _a[routes_1.ROUTES.PROSPECTING_DIRECT_RENT_SCIS_SIREN] = 'SCIs',
    // Monitoring
    _a[routes_1.ROUTES.MONITORING_DASHBOARD] = 'Surveillance',
    _a[routes_1.ROUTES.MONITORING_BAUX_COMMERCIAUX] = 'Sociétés / Commerces / Pieds d\'immeubles',
    _a[routes_1.ROUTES.MONITORING_BAUX_A_ECHEANCE] = 'Baux à échéance',
    _a[routes_1.ROUTES.MONITORING_ENTREPRISES_EN_DEFAILLANCE] = 'Commerces et sociétés (surveillance)',
    _a[routes_1.ROUTES.MONITORING_ENTREPRISES_SUIVIES] = 'Surveillance gestion locative',
    // Quick Access
    _a[routes_1.ROUTES.QUICK_ACCESS_COPRO_MANAGING] = 'Mes copropriétés',
    _a[routes_1.ROUTES.QUICK_ACCESS_COPRO_VIGILANCE] = 'Vigilance copropriétés',
    _a[routes_1.ROUTES.QUICK_ACCESS_GENERAL_ASSEMBLY] = 'Assemblée générale',
    _a[routes_1.ROUTES.QUICK_ACCESS_GESTIONNAIRES_COPROS] = 'Répartition des gestionnaires',
    // Communication
    _a[routes_1.ROUTES.COMMUNICATION_DASHBOARD] = 'Communication',
    _a[routes_1.ROUTES.COMMUNICATION_NEWSLETTERS] = 'Newsletters',
    _a[routes_1.ROUTES.COMMUNICATION_NEWSLETTER_PREVIEW] = 'Newsletter',
    _a[routes_1.ROUTES.COMMUNICATION_PAGE_SITE] = 'Page à inclure',
    _a[routes_1.ROUTES.COMMUNICATION_SCRIPT_APPEL] = 'Script d\'appel',
    // Admin
    _a[routes_1.ROUTES.ADMIN_IMPORT] = 'Import des ASL/AFUL/Copro',
    _a[routes_1.ROUTES.ADMIN_DIFF] = 'Import d\'un fichier différentiel',
    _a[routes_1.ROUTES.ADMIN_COMPANY_VERIFICATION] = 'Entreprises à valider',
    _a[routes_1.ROUTES.ADMIN_COPRO_VALIDATION] = 'Validation des copropriétés',
    _a[routes_1.ROUTES.ADMIN_COMPANIES_LIST] = 'Liste des entreprises',
    _a[routes_1.ROUTES.ADMIN_COMPANY_DETAILS] = 'Détails de l\'entreprise',
    _a[routes_1.ROUTES.ADMIN_SIRET_TRACKED_IMPORT] = 'Import des SIRETs suivis',
    _a[routes_1.ROUTES.ADMIN_BLOG_POSTS] = 'Articles',
    _a[routes_1.ROUTES.ADMIN_BLOG_POST_EDIT] = 'Article',
    _a[routes_1.ROUTES.ADMIN_NEWSLETTER] = 'Newsletters',
    _a[routes_1.ROUTES.ADMIN_NEWSLETTER_EDIT] = 'Newsletter',
    _a);
