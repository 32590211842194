"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var useFreemiumGuard = function (blockedUrls) {
    if (blockedUrls === void 0) { blockedUrls = []; }
    var location = (0, react_router_dom_1.useLocation)();
    var company = (0, react_redux_1.useSelector)(function (state) { return state.auth.company; });
    var isBlockedUrl = blockedUrls.includes(location.pathname);
    if (company && company.id && !company.hasPaidAccess && isBlockedUrl) {
        return true;
    }
    if (company && !company.id && isBlockedUrl) {
        // user is still loading, we don't want to block the page as no query will be done without company
        return false;
    }
    return false;
};
exports.default = useFreemiumGuard;
