"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.MODULES = exports.MODULE_ORDER = exports.QUICK_ACCESS = exports.MODULE_PRESENTATION = exports.MODULE_MONITORING = exports.MODULE_DIRECT_RENT = exports.MODULE_DIRECT_TRANSACTION = exports.MODULE_DIRECT_ALL_NEW_DPE = exports.MODULE_INCOMING_RENT = exports.MODULE_INCOMING_TRANSACTION = exports.MODULE_COMMUNICATION = exports.MODULE_ANALYZING = exports.MODULE_SAFETY = void 0;
var routes_1 = require("./routes");
// Submodule names
exports.MODULE_SAFETY = 'Sécuriser';
exports.MODULE_ANALYZING = 'Analyser';
exports.MODULE_COMMUNICATION = 'Communiquer';
exports.MODULE_INCOMING_TRANSACTION = 'Transaction entrante';
exports.MODULE_INCOMING_RENT = 'Location entrante';
exports.MODULE_DIRECT_ALL_NEW_DPE = 'Tous les nouveaux DPEs';
exports.MODULE_DIRECT_TRANSACTION = 'Transaction directe';
exports.MODULE_DIRECT_RENT = 'Location directe';
exports.MODULE_MONITORING = 'Surveiller';
exports.MODULE_PRESENTATION = 'Rapports / Appels entrants';
// Other constants for elements always accessible
exports.QUICK_ACCESS = 'Accès rapide';
// Module order (if still needed)
exports.MODULE_ORDER = (_a = {},
    _a[exports.MODULE_SAFETY] = 1,
    _a[exports.MODULE_ANALYZING] = 2,
    _a[exports.MODULE_INCOMING_TRANSACTION] = 3,
    _a[exports.MODULE_INCOMING_RENT] = 4,
    _a[exports.MODULE_DIRECT_ALL_NEW_DPE] = 5,
    _a[exports.MODULE_DIRECT_TRANSACTION] = 6,
    _a[exports.MODULE_DIRECT_RENT] = 7,
    _a[exports.MODULE_MONITORING] = 8,
    _a[exports.MODULE_COMMUNICATION] = 9,
    _a[exports.MODULE_PRESENTATION] = 10,
    _a);
// Main module names
exports.MODULES = {
    CARTOGRAPHIER: { title: 'Cartographier',
        subtitle: 'Sécuriser / Analyser',
        img: require('../../img/market-vision.png'),
        subModules: [
            {
                title: 'Sécuriser',
                subtitle: 'Sécuriser votre parc',
                img: require('../../img/registre-coproprietes.png'),
                route: routes_1.ROUTES.SECURING_DASHBOARD,
                module: exports.MODULE_SAFETY,
            },
            {
                title: 'Analyser',
                subtitle: 'Analyser vos données',
                img: require('../../img/baux_commerciaux.webp'),
                route: routes_1.ROUTES.ANALYZING_DASHBOARD,
                module: exports.MODULE_ANALYZING,
            },
        ],
    },
    COMMUNIQUER: {
        title: 'Communiquer',
        subtitle: 'A vos copropriétaires',
        img: require('../../img/communicationModule.webp'),
        subModules: [
            {
                title: 'Newsletters',
                subtitle: '3 FOIS PAR AN RGPD COMPATIBLES',
                img: require('../../img/communicationModule.webp'),
                route: routes_1.ROUTES.COMMUNICATION_DASHBOARD,
                module: exports.MODULE_COMMUNICATION,
            },
            {
                title: 'Communications AG',
                subtitle: 'Communications aux AG RGPD compatibles',
                img: require('../../img/communication-AG.png'),
                route: routes_1.ROUTES.COMMUNICATION_PAGE_SITE,
                module: exports.MODULE_COMMUNICATION,
            },
            {
                title: 'Outils copropriétaires',
                subtitle: 'Outils pour obtenir le consentement',
                img: require('../../img/public-QR.png'),
                route: routes_1.ROUTES.COMMUNICATION_SCRIPT_APPEL,
                module: exports.MODULE_COMMUNICATION,
            },
        ],
    },
    PROSPECTER: {
        title: 'Prospecter',
        subtitle: 'Transaction / location / gestion locative',
        img: require('../../img/transaction.webp'),
        subModules: [
            { title: 'Pour les demandes / appels entrants',
                subModules: [{
                        title: 'Prospection transaction',
                        subtitle: 'Appels entrants',
                        img: require('../../img/call-prospect-transaction.png'),
                        route: routes_1.ROUTES.PROSPECTING_INCOMING_TRANSACTION,
                        module: exports.MODULE_INCOMING_TRANSACTION,
                    },
                    {
                        title: 'Prospection loc / gestion loc',
                        subtitle: 'Appels entrants',
                        img: require('../../img/call-prospect-loc.png'),
                        route: routes_1.ROUTES.PROSPECTING_INCOMING_RENT,
                        module: exports.MODULE_INCOMING_RENT,
                    }],
            },
            { title: 'Prospection directe',
                subModules: [{
                        title: 'Tous les nouveaux DPEs dans vos copropriétés',
                        subtitle: 'Les nouvelles opportunités chaque semaine',
                        img: require('../../img/dpe.webp'),
                        route: routes_1.ROUTES.PROSPECTING_DIRECT_ALL_NEW_DPE,
                        module: exports.MODULE_DIRECT_ALL_NEW_DPE,
                    },
                    {
                        title: 'Prospection transaction',
                        subtitle: '',
                        img: require('../../img/propsect-transaction.png'),
                        route: routes_1.ROUTES.PROSPECTING_DIRECT_TRANSACTION,
                        module: exports.MODULE_DIRECT_TRANSACTION,
                    },
                    {
                        title: 'Prospection loc / gestion loc',
                        subtitle: '',
                        img: require('../../img/prospect-loc.png'),
                        route: routes_1.ROUTES.PROSPECTING_DIRECT_RENT,
                        module: exports.MODULE_DIRECT_RENT,
                    }],
            },
        ],
    },
    PILOTER: {
        title: 'Piloter',
        subtitle: 'Les différentes actions',
        img: require('../../img/piloter.png'),
        subModules: [
            {
                title: 'Suivi des entreprises',
                subtitle: 'dans vos copropriétés',
                img: require('../../img/BODACC.webp'),
                route: routes_1.ROUTES.MONITORING_DASHBOARD,
                module: exports.MODULE_MONITORING,
            },
        ],
    },
};
